import { HttpErrorResponse } from '@angular/common/http';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import find from 'lodash-es/find';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';
import map from 'lodash-es/map';
import {
  Observable,
  concat as observableConcat,
  defer as observableDefer,
  merge as observableMerge,
  of as observableOf,
} from 'rxjs';
import { delay, distinctUntilChanged, filter, map as observableMap, pairwise } from 'rxjs/operators';

export interface ControlChange {
  control: string | number;
  controlValue: any;
}

export interface FormChange extends ControlChange {
  formValue: any;
  formValid: boolean;
}

/**
 * register form changes as change per field
 */
export const formChange = (form: UntypedFormGroup | UntypedFormArray): Observable<FormChange> => {
  const fieldChangeObservables = map(form.controls, fieldChange);

  return observableMerge(...fieldChangeObservables).pipe(
    //check against current form value
    filter((fieldChange) => form.value[fieldChange.control] !== fieldChange.controlValue),
    //wait 1 tick so the changes have propagated to the parent form
    delay(0),
    observableMap((controlChange) => ({
      ...controlChange,
      formValue: form.value,
      formValid: form.valid,
    })),
  );
};

export const fieldChange = (control: AbstractControl, fieldName: string | number): Observable<ControlChange> =>
  control.valueChanges.pipe(
    distinctUntilChanged(),
    observableMap((controlValue) => ({
      control: fieldName,
      controlValue,
    })),
  );

/**
 * Listen for fieldChanges that emits the current value on subscription
 */
export const fieldChangeWithStart = (control: AbstractControl) =>
  observableConcat(
    observableDefer(() => observableOf(control.value)),
    control.valueChanges,
  ).pipe(distinctUntilChanged());

/**
 * Listen for fieldChanges that emits the previous and current value on subscription
 */
export const fieldChangeWithStartPair = (control: AbstractControl) =>
  observableConcat(
    observableDefer(() => observableOf(control.value)),
    control.valueChanges,
  ).pipe(distinctUntilChanged(), pairwise());

export const getControlName = (control: AbstractControl) => {
  const parent = control.parent;

  // only such parent, which is FormGroup, has a dictionary
  // with control-names as a key and a form-control as a value
  if (parent instanceof UntypedFormGroup) {
    // now we will iterate those keys (i.e. names of controls)
    Object.keys(parent.controls).forEach((name) => {
      // and compare the passed control and
      // a child control of a parent - with provided name (we iterate them all)
      if (control === parent.controls[name]) {
        // both are same: control passed to Validator
        //  and this child - are the same references
        return name;
      }
    });
  }
};

/**
 * Handle a form http configureForm validation error
 * @param error
 * @param form
 */
export const handleSubmitError = (error: HttpErrorResponse, form: AbstractControl) => {
  // only handle validation errors
  if (error.status !== 422) {
    return;
  }

  const responseData = error.error;

  if (!responseData.meta || !responseData.meta.validation_errors) {
    return;
  }

  handleFormError(responseData.meta.validation_errors, form);
};

const handleFormError = (error, form: AbstractControl) => {
  if (form instanceof UntypedFormGroup || form instanceof FormGroup) {
    handleFormGroupError(error, form);
  }

  if (form instanceof UntypedFormArray || form instanceof FormArray) {
    handleFormArrayError(error, form);
  }

  if (form instanceof UntypedFormControl || form instanceof FormControl) {
    handleFormControlError(error, form);
  }
};

const handleFormGroupError = (errors, form: UntypedFormGroup) => {
  Object.keys(form.controls).forEach((controlName) => {
    if (!errors[controlName]) {
      return;
    }

    handleFormError(errors[controlName], form.controls[controlName]);
  });
};

const handleFormArrayError = (errors, form: UntypedFormArray) => {
  form.controls.forEach((control: AbstractControl, index) => {
    if (!errors[index]) {
      return;
    }

    handleFormError(errors[index], control);
  });
};

const handleFormControlError = (errors, form: UntypedFormControl) => {
  if (isEmpty(errors)) {
    return;
  }

  let error;
  if (isArray(errors)) {
    error = errors[0];
  }

  if (isObject(errors)) {
    //return first error
    error = find(errors, () => true);
  }

  if (!isString(error)) {
    return;
  }

  form.setErrors({ serverSide: error });
};

export const removeError = (control: AbstractControl, error: string) => {
  const err = control.errors; // get control errors
  if (err) {
    delete err[error]; // delete your own error
    if (!Object.keys(err).length) {
      // if no errors left
      control.setErrors(null); // set control errors to null making it VALID
    }
  }
};

export const validateFormFields = (control: AbstractControl) => {
  if (control instanceof FormGroup) {
    const group = control as FormGroup;

    for (const field in group.controls) {
      const c = group.controls[field];

      validateFormFields(c);
    }
  } else if (control instanceof FormArray) {
    const group = control as FormArray;

    for (const field in group.controls) {
      const c = group.controls[field];

      validateFormFields(c);
    }
  }

  control.markAsDirty({ onlySelf: false });
  control.updateValueAndValidity({ onlySelf: false });
};
